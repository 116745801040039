import React from "react";

const LinkedInIcon = ({ width = "auto", height = "auto", style = {} }) =>
    <svg role="img" viewBox="0 0 1080 866" style={{ ...style, width: width, height: height }}>
        <title>LinkedIn icon</title>
        <g transform="matrix(1.71441,0,0,1.6223,3.24185e-13,0)">
            <path d="M495.069,0C517.794,0 536.216,18.301 536.216,40.878L536.216,492.932C536.216,515.508 517.794,533.809 495.069,533.809L41.147,533.809C18.422,533.809 -0,515.508 -0,492.932L0,40.878C-0,18.301 18.422,0 41.147,0L495.069,0ZM158.95,200.33L78.993,200.33L78.993,454.459L158.95,454.459L158.95,200.33ZM208.541,200.33L208.541,454.459L288.574,454.459L288.654,315.532C288.654,315.532 287.845,263.191 334.808,263.191C381.772,263.191 377.123,316.153 377.123,327.394L377.134,454.459L456.978,454.459C456.978,454.459 456.816,349.174 456.744,327.394C456.542,265.936 455.953,195.996 364.097,193.835C314.929,192.679 295.159,221.438 285.43,235.825L285.43,200.33L208.541,200.33ZM118.838,73.734C93.287,73.734 72.544,94.342 72.544,119.725C72.544,145.108 93.287,165.716 118.838,165.716C144.388,165.716 165.132,145.108 165.132,119.725C165.132,94.342 144.388,73.734 118.838,73.734Z" />
        </g>
    </svg>;

export default LinkedInIcon;
