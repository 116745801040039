import React from "react";

const SGIcon = ({ width = "auto", height = "auto", style = {} }) =>
    <svg role="img" viewBox="0 0 925 838" style={{ ...style, width: width, height: height }}>
        <title>Samantha Gatt icon</title>
        <g transform="matrix(2.26982,0,0,2.27008,-124.84,-165.771)">
            <path d="M55,317.206C57.948,303.582 72.068,278.559 73.895,275.414C68.855,257.384 69.658,237.195 71.743,224.249C73.915,210.759 79.54,184.795 94.953,164.752C103.738,162.559 109.863,166.852 111.848,168.218C110.324,173.311 103.887,180.205 98.244,195.866C94.449,206.398 87.998,230.562 90.55,244.153L175.248,100.388C178.532,87.221 183.6,78.185 186.102,75.158C190.697,71.488 204.954,72.578 210.356,78.231C210.94,81.356 205.858,103.882 205.438,116.922C205.031,129.553 209.103,211.584 210.369,231.029C212.848,269.104 200.701,309.929 169.06,320.779C133.074,333.12 99.421,318.087 84.478,296.259C82.417,301.115 71.249,319.341 69.291,321.532C65.707,322.609 57.176,322.001 55,317.206ZM95.701,273.691C97.306,278.396 116.376,310.05 134.521,309.574C152.665,309.097 165.517,288.987 171.038,267.919C175.745,249.957 174.793,225.025 174.405,201.321C173.852,167.572 171.886,137.126 171.886,137.126L95.701,273.691Z" />
            <path d="M347.823,105.266C347.823,105.266 353.76,85.939 364.045,80.067C370.798,79.359 382.113,77.598 387.856,88.665C374.811,115.513 372.639,166.087 373.652,200.499C374.703,236.176 377.119,284.366 372.635,325.54C384.377,326.974 409.949,333.829 427.081,343.212C443.807,352.372 451.539,362.007 454.463,365.817C463.231,377.242 470.462,406.189 441.621,422.788C412.78,439.388 345.361,437.07 337.963,435.728C333.617,434.939 325.62,430.974 328.03,426.925C330.694,422.449 335.5,426.741 342.421,427.568C349.341,428.395 414.088,428.545 434.933,407.648C458.745,383.778 436.767,341.077 370.522,333.452C356.646,405.572 290.424,435.689 242.358,441.154C195.12,446.525 142.553,422.974 160.41,386.499C174.853,356.997 225.968,336.24 268.915,328.45C303.003,322.267 332.949,322.664 338.895,322.643C341.881,310.727 340.18,243.787 339.462,226.655C324.079,265.751 312.191,279.885 300.819,292.839C287.696,307.785 246.071,313.979 228.728,278.438C210.585,241.257 237.427,163.377 273.445,122.973C308.12,84.075 340.094,98.542 347.823,105.266ZM338.073,331.03C313.049,330.989 283.032,333.236 247.629,343.96C208.687,355.755 173.324,383.97 177.071,403.499C182.799,433.36 227.669,435.461 261.139,422.17C284.849,412.755 328.551,385.951 338.073,331.03ZM338.111,168.613C338.466,156.845 341.675,123.4 344.019,119.039C341.929,116.327 333.718,108.857 328.969,107.347C319.768,106.738 294.557,133.16 278.926,172.821C263.296,212.482 249.344,261.417 268.225,287.029C284.249,279.08 300.279,253.143 310.883,234.468C322.592,213.845 335.685,180.637 338.111,168.613Z" />
        </g>
    </svg>;

export default SGIcon;
